
.inputBox {
    display: block;
    margin-left: 20px;
    margin-top: 5px;
    padding: 5px;
    border: 1px solid orange;
}

.signup {
    
}

.invalid-text {
    display: inline-block;
    margin-left: 20px;
    padding-left: 5px;
    color: greenyellow;
}
/*
.main-hero-container {
    height: 76vh;
    display: flex; 
    margin-top: 2rem;
    /*background-image: url(../public/images/MH2-1593534596450.avif);
    background-size: cover;
    color: blueviolet;
    text-decoration:solid;
    text-align: left;
    background-color: aqua;
    
    
}

.header-right-side {
    align-items:right;
    color:white;
    font-weight: bold;
    margin-top: 10rem;
    background-color: green; 
    
}

.header-left-side {
    background-color: yellow; 
}
*/

.bottom-left {
    bottom: 15rem;
    left: 10rem;
    position: absolute;
    font-weight: bold;
    background-color: aqua;
}

.check {
    background-color: blueviolet;
    height: 10rem;
}

.card-text {
    color: white;
    
}

.card-title {
    color: white;
    font-weight: bold;
    
}



.btn-outline-success {
    width: 10rem;
}


@media only screen and (min-width:sm ) and (max-width: lg){
    
}
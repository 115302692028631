.main-nav {
    width: 100%;
    height: 5rem;
    display: grid;
    grid-template-columns: 10rem 1fr 2fr 1fr 10rem;
    /*background-color: aqua;*/

    /* box-shadow: rgba(50,50,93,0.25) 0px 50px 100px -20px,
    rgba(0,0,0,0.3) 0px 30px 60px -30px; */

    box-shadow: rgba(50,50,93,0.25) 0px 50px 100px -20px,
    rgba(0,0,0,0.3) 0px 30px 60px -30px;
}

.logo {
    display: grid;
    /*background-color: aquamarine;*/
    grid-column: 2/3;
    justify-content: start;
    align-items: start;
}

.menu-link {
    grid-column: 3/4;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-weight: 900;
    font-size: large;
}

.menu-link ul {
    height: 5rem;
    display: flex;
    justify-content: space-around;
    align-items: start;
}

.menu-link ul {
    font-size: 1.1rem;
    
}

.menu-link ul a {
    text-transform: capitalize;
    transition: 0.5s;
    text-decoration: none;
    color: blue;
}

.menu-link ul a:hover {
    color: var(--primary--color)!important;
}

.social-media {
    grid-column: 4/5;
}

.social-media ul{
    height: 5rem;
    display: grid;
    grid-template-columns: 3fr repeat(3, 1fr);
    align-items: start;
}

.social-media ul li:first-child{
    grid-column: 2/3;
}

.social-media ul {
    text-align: right;
}

.social-media .hamburger-menu {
    display: none;
}

.hero-section h1 {
    font-size: 5rem;
    text-transform: uppercase;
    text-align: center;
}

/* responsive css style */
@media(max-width:1080px) {
    .main-nav {
        height: 8rem;
        grid-template-columns: 2rem 3fr 3fr 1fr 2rem;
    }

    .logo 
    .menu-link
    .social-media{
        height: 8rem;
    }
}


@media (max-width: 998px) {
    .main-nav {
        height: 7rem;
        grid-template-columns: 2rem 2fr 3fr 2rem 2rem;
    }
    
    .menu-link{
        display: none;
    }

    
    .logo,
    .social-media ul {
        height: 7rem;
    }

    .mobile-menu-link {
        grid-column: 2/4;
        position: relative;
        z-index: 99;
    }

    .mobile-menu-link {
        background-color: white;
        height: 20rem;
        display: grid;
        grid-column: 2/5;
        align-items: center;
        padding-left: 3rem;
        transition: all 2s linear;
        transform-origin: top;
        box-shadow: rgba(50,50,93,0.25) 0px 50px 100px -20px,
        rgba(0,0,0,0.3) 0px 30px 60px -30px;
    }

    .mobile-menu-link ul {
        height: 20rem;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: start;
    }

    .mobile-menu-link ul li:first-child {
        transition-delay: 0.2s;
    }

    .social-media {
        grid-row: 1/2;
        grid-column: 3/5;
        justify-items: end;
        align-items: center;
        transition: all 2s linear;
    }

    .social-media .social-media-desktop {
        height: 0;
        display: none;
    }

    .social-media {
        height: 7rem;
        display: flex;
        justify-self: end;
        align-items: center;
    }

    .social-media .hamburger-menu {
        display: block;
        font-size: 2.5rem;
    }

}

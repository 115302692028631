
.card-img-top {
    width: 100%;
    height: 15vw;
    object-fit: cover;
}

@media only screen and (min-width: 200px) and (max-width: 576px) {
    .card-img-top {
        height: 70vw;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .card-img-top {
        height: 15vw;
    }
}


